import * as AWS from 'aws-sdk';

export const getObjectS3 = async (Bucket, Key) => {
  const S3Object = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1',
  });
  const Location = await S3Object.getSignedUrl('getObject', {
    Bucket,
    Key,
    Expires: 300,
  });
  return Location;
};

export const saveToS3 = async (Bucket, Key, Body, ContentType, ACL) => {
  const S3Object = new AWS.S3({
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    apiVersion: '2016-03-01',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1',
  });

  await S3Object.putObject({
    Bucket,
    Key,
    Body,
    ContentType,
    ACL,
  }).promise();
};
