/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import api from 'api';
import parser from 'ua-parser-js';
import publicIp from 'react-public-ip';
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';
import { saveFilesBucket } from './helper';
import axios from 'axios';

import {
  Container,
  CustomMessageArea,
  Logo,
  PhotoRequestArea,
  PhotoPreviewArea,
  ExampleImage,
  PreviewImage,
  ErrorArea,
  HelpArea,
} from './styles';
import { Button } from 'components';

import logoImg from 'assets/logo.png';
import animation from 'assets/satellite.json';
import animationError from 'assets/error.json';

function Home({ location }) {
  const inspectionCode = location.pathname.split('/')[1];

  const [inspection, setInspection] = useState();
  const [geolocation, setGeolocation] = useState();
  const [currentPhoto] = useState(0);
  const [metadata, setMetadata] = useState();
  const [selectedPhotoFile, setSelectedPhotoFile] = useState();
  const [selectedPhotoData, setSelectedPhotoData] = useState();
  const [loadingInspection, setLoadingInspection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const [photosToTake, setPhotosToTake] = useState();
  const [photosTaken, setPhotosTaken] = useState([]);

  const [step, setStep] = useState(1);

  const [theme, setTheme] = useState();
  const [logo, setLogo] = useState();

  const fileInputImageAllow = useRef();
  const fileInputImageDeny = useRef();
  const fileInputVideoAllow = useRef();
  const fileInputVideoDeny = useRef();
  const fileInputImagePdf = useRef();

  const userAgent = navigator.userAgent;

  const loadInspection = async () => {
    setLoadingInspection(true);
    const resp = await api.get(`/load-inspection/${inspectionCode}`);
    setInspection(resp.data);
    setTheme(resp?.data?.visualId?.company_theme && JSON.parse(resp?.data?.visualId?.company_theme));
    setLogo(resp?.data?.visualId?.company_logo && JSON.parse(resp?.data?.visualId?.company_logo));
    const photosToTake = resp.data.photos?.filter((i) => !i.has_sent);
    setPhotosToTake(photosToTake);
    if (
      resp.data.status &&
      (resp.data.status === 'expired' ||
        resp.data.status === 'canceled' ||
        resp.data.status === 'finished' ||
        resp.data.application !== 'webapp')
    )
      setStep(98);

    if (!photosToTake || photosToTake?.length < 1) setStep(4);

    setLoadingInspection(false);
  };

  const loadGeolocation = async () => {
    navigator.geolocation.getCurrentPosition((e) => setGeolocation(e.coords));
  };

  useEffect(() => {
    loadInspection();
  }, []);

  useEffect(() => {
    loadGeolocation();
  }, []);

  useEffect(() => {
    if (
      userAgent.toLowerCase().includes('windows') ||
      userAgent.toLowerCase().includes('macintosh') ||
      navigator.maxTouchPoints < 2
    ) {
      setStep(97);
    }
  }, [userAgent, navigator.maxTouchPoints]);

  const handleStart = async () => {
    const ip = await axios.get('https://icanhazip.com/');
    const ipv4 = ip.data || '';
    const os = `${parser(userAgent)?.os?.name} ${parser(userAgent)?.os?.version}`;
    const device = `${parser(userAgent)?.device?.vendor} ${parser(userAgent)?.device?.model}`;

    if (geolocation && geolocation?.latitude && geolocation?.longitude) {
      if (!photosToTake.length || photosToTake.length === 0) {
        handleFinish();
      } else {
        setMetadata(
          `${ipv4} | ${new Date()} | ${geolocation?.latitude}, ${geolocation?.longitude} | ${device} | ${os} | ${
            window.navigator.userAgent
          }`
        );
        await api({
          method: 'PUT',
          url: `/start-inspection/${inspection?.id}`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            start_location: `${geolocation?.latitude}, ${geolocation?.longitude}`,
            start_device: `${device}, ${os}`,
            ip: ipv4,
          },
          json: true,
        });
        setStep(step + 1);
      }
    } else {
      // setStep(step + 1);
      setStep(99);
    }
  };

  const handleUploadPhoto = async () => {
    setLoading(true);
    const ext = selectedPhotoData?.name?.split('.').pop();
    saveFilesBucket({
      Bucket: 'vst-photos',
      Body: selectedPhotoData,
      Key: `${inspection?.company?.subdomain}/${inspection?.identificator}/${inspection?.inspection_code}/${
        photosToTake[0]?.slug
      }-${new Date().getTime()}.${ext}`,
      onEnd: async (err, data) => {
        try {
          await api.post('/upload-photo', {
            id_inspection: inspection?.id,
            id_photo: photosToTake[0]?.id,
            file: data?.file,
            metadata,
          });

          const currentPhotoList = photosToTake;
          const newPhotoList = photosTaken;
          newPhotoList?.push(currentPhotoList[0]);

          currentPhotoList.splice(0, 1);
          setPhotosToTake(currentPhotoList);
          setPhotosTaken(newPhotoList);

          if (currentPhotoList.length > 0) {
            setLoading(false);
            setStep(2);
          } else {
            setLoading(false);
            handleFinish();
          }
        } catch (e) {
          console.log(err);
          console.log(e);
        }
      },
    });
  };

  const handleChangePhoto = async (e) => {
    setSelectedPhotoFile(URL.createObjectURL(e.target.files[0]));
    setSelectedPhotoData(e.target.files[0]);
    setStep(3);
  };

  const handleReload = () => {
    document.location.reload(true);
  };

  const handleFinish = async () => {
    setLoading(true);
    await loadGeolocation();
    const ipv4 = (await publicIp.v4()) || '';
    const os = `${parser(userAgent)?.os?.name} ${parser(userAgent)?.os?.version}`;
    const device = `${parser(userAgent)?.device?.vendor} ${parser(userAgent)?.device?.model}`;

    if (geolocation && geolocation?.latitude && geolocation?.longitude) {
      setMetadata(`${ipv4} | ${new Date()} | ${geolocation?.latitude}, ${geolocation?.longitude} | ${device} | ${os} `);
      await api({
        method: 'PUT',
        url: `/finish-inspection/${inspection?.id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          end_location: `${geolocation?.latitude}, ${geolocation?.longitude}`,
          end_device: `${device}, ${os}`,
          metadata,
        },
        json: true,
      });
      setStep(4);
      setLoading(false);
    } else {
      setStep(99);
      setLoading(false);
    }
  };

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const animationErrorOptions = {
    loop: false,
    autoplay: true,
    animationData: animationError,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const loadedLogo = logo?.defaultLogo ? logo?.defaultLogo : logoImg;
  return (
    <Container>
      {(loadingInspection || loading) && <ReactLoading type="spin" color="#ecf0f1" height={45} width={45} />}

      {!loadingInspection && (
        <>
          {/* LOCATION ERROR */}
          {step === 99 && !loading && (
            <ErrorArea>
              <Lottie options={animationOptions} height={150} width={150} />
              <h3>Oops!</h3>
              <h4>
                Infelizmente não conseguimos acessar a sua localização. Por favor, verifique as permissões do seu
                dispositivo.
              </h4>
              <Button color={theme?.primary} onClick={handleReload}>
                Tentar novamente
              </Button>

              <small onClick={() => setShowHelp(true)}>Precisa de ajuda?</small>

              <SwipeableDrawer anchor="bottom" open={showHelp} onClose={() => setShowHelp(false)}>
                <HelpArea>
                  <h2>Ajuda - Localização</h2>
                  <h1>iOS (iPhones)</h1>
                  <ol>
                    <li>
                      Acesse Ajustes {'>'} Privacidade e Segurança {'>'} Serviços de Localização.
                    </li>
                    <li>Veja se os Serviços de Localização estão ativados.</li>
                    <li>Role para baixo e encontre o app Safari.</li>
                    <li>Toque no app e selecione uma opção Permitir.</li>
                  </ol>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.apple.com/pt-br/HT207092#:~:text=Como%20ativar%20ou%20desativar%20os%20Servi%C3%A7os%20de%20Localiza%C3%A7%C3%A3o%20de%20apps%20espec%C3%ADficos"
                  >
                    Ver mais...
                  </a>

                  <h1>Android</h1>
                  <ol>
                    <li>Deslize de cima para baixo na tela</li>
                    <li>
                      Toque em Local e essa opção mantenha pressionada. Se Local não estiver disponível: Toque em Editar
                      ou Configurações. Em seguida, arraste Local para as Configurações rápidas.
                    </li>
                    <li>Toque em Permissões de acesso ao local.</li>
                    <li>Toque no app e selecione uma opção Permitir.</li>
                    <li>
                      Nas opções "Permitido sempre", "Permitido durante o uso" e "Não permitido", encontre os apps que
                      podem usar a localização do smartphone.
                    </li>
                    <li>
                      Para mudar as permissões, toque no app e escolha o acesso ao local que você quer conceder a ele.
                    </li>
                  </ol>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.google.com/android/answer/6179507#:~:text=do%20smartphone%20Android-,Escolher,-quais%20apps%20usam"
                  >
                    Ver mais...
                  </a>

                  <Button color={theme?.primary} onClick={() => setShowHelp(false)} style={{ marginTop: 10 }}>
                    Voltar
                  </Button>
                </HelpArea>
              </SwipeableDrawer>
            </ErrorArea>
          )}

          {/* INSPECTION ERROR */}
          {step === 98 && !loading && (
            <ErrorArea>
              <Lottie options={animationErrorOptions} height={250} width={250} />
              <h3 style={{ marginTop: -40 }}>Oops!</h3>
              <h4>Essa vistoria já foi realizada ou não está mais disponível.</h4>
            </ErrorArea>
          )}

          {/* NOT IN MOBILE ERROR */}
          {step === 97 && !loading && (
            <ErrorArea>
              <Lottie options={animationErrorOptions} height={250} width={250} />
              <h3 style={{ marginTop: -40 }}>Oops!</h3>
              <h4 style={{ margin: 0, marginTop: 20 }}>
                Parece que você está tentando acessar este link por um computador.
              </h4>
              <h4 style={{ margin: 0, marginTop: 20, fontSize: 16 }}>
                Para realizar a vistoria, certifique-se de usar um tablet ou smartphone.
              </h4>
            </ErrorArea>
          )}

          {/* WELCOME */}
          {step === 1 && !loading && (
            <CustomMessageArea>
              <Logo src={loadedLogo} />
              <h3>Olá, {inspection?.client_name?.split(' ')[0]}!</h3>
              <h4>{inspection?.data?.welcome_message}</h4>
              <Button color={theme?.primary} onClick={() => handleStart()}>
                {inspection?.start_date ? 'Continuar' : 'Começar'}
              </Button>
            </CustomMessageArea>
          )}

          {/* TAKE PHOTO */}
          {step === 2 && !loading && (
            <PhotoRequestArea>
              <h4>{photosToTake[currentPhoto]?.description}</h4>
              <ExampleImage image={photosToTake[currentPhoto]?.picture} />
              <p>{photosToTake[currentPhoto]?.help_text}</p>

              {/* INPUTS */}

              {/* IMAGE - DENY UPLOAD */}
              <input
                type="file"
                accept="image/*"
                capture="environment"
                style={{ display: 'none' }}
                ref={fileInputImageDeny}
                onChange={handleChangePhoto}
              />

              {/* IMAGE - ALLOW UPLOAD */}
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputImageAllow}
                onChange={handleChangePhoto}
              />

              {/* VIDEO - DENY UPLOAD */}
              <input
                type="file"
                accept="video/*"
                capture="environment"
                style={{ display: 'none' }}
                ref={fileInputVideoDeny}
                onChange={handleChangePhoto}
              />

              {/* VIDEO - ALLOW UPLOAD */}
              <input
                type="file"
                accept="video/*"
                style={{ display: 'none' }}
                ref={fileInputVideoAllow}
                onChange={handleChangePhoto}
              />

              {/* IMAGE_PDF - ALLOW UPLOAD */}
              <input
                type="file"
                accept="image/*,application/pdf"
                style={{ display: 'none' }}
                ref={fileInputImagePdf}
                onChange={handleChangePhoto}
              />

              {/* INPUT PICTURE - ALLOW UPLOAD */}
              {photosToTake[currentPhoto]?.mediatype === 'picture' &&
                photosToTake[currentPhoto]?.allow_upload === 1 && (
                  <Button
                    color={theme?.primary}
                    onClick={(e) => {
                      fileInputImageAllow.current.click();
                    }}
                  >
                    Tirar ou carregar foto
                  </Button>
                )}

              {/* INPUT PICTURE - DENY UPLOAD */}
              {photosToTake[currentPhoto]?.mediatype === 'picture' &&
                photosToTake[currentPhoto]?.allow_upload === 0 && (
                  <Button
                    color={theme?.primary}
                    onClick={(e) => {
                      fileInputImageDeny.current.click();
                    }}
                  >
                    Tirar foto
                  </Button>
                )}

              {/* INPUT VIDEO - ALLOW UPLOAD */}
              {photosToTake[currentPhoto]?.mediatype === 'video' && photosToTake[currentPhoto]?.allow_upload === 1 && (
                <Button
                  color={theme?.primary}
                  onClick={(e) => {
                    fileInputVideoAllow.current.click();
                  }}
                >
                  Gravar ou carregar vídeo
                </Button>
              )}

              {/* INPUT VIDEO - DENY UPLOAD */}
              {photosToTake[currentPhoto]?.mediatype === 'video' && photosToTake[currentPhoto]?.allow_upload === 0 && (
                <Button
                  color={theme?.primary}
                  onClick={(e) => {
                    fileInputVideoDeny.current.click();
                  }}
                >
                  Gravar vídeo
                </Button>
              )}

              {/* INPUT PDF - ALLOW UPLOAD */}
              {photosToTake[currentPhoto]?.mediatype === 'pdf' && photosToTake[currentPhoto]?.allow_upload === 1 && (
                <Button
                  color={theme?.primary}
                  onClick={(e) => {
                    fileInputImageAllow.current.click();
                  }}
                >
                  Carregar PDF
                </Button>
              )}

              {/* INPUT IMAGE_PDF */}
              {photosToTake[currentPhoto]?.mediatype === 'picture_pdf' &&
                photosToTake[currentPhoto]?.allow_upload === 1 && (
                  <Button
                    color={theme?.primary}
                    onClick={(e) => {
                      fileInputImagePdf.current.click();
                    }}
                  >
                    Carregar Foto ou PDF
                  </Button>
                )}
            </PhotoRequestArea>
          )}

          {/* PREVIEW PHOTO */}
          {step === 3 && !loading && (
            <PhotoPreviewArea>
              <Logo src={loadedLogo} />
              <h4>Esta é a foto que você tirou</h4>
              <PreviewImage src={selectedPhotoFile} />
              <p>Se estiver tudo certo, você pode continuar. Caso contrário, você pode tirar outra foto.</p>
              <Button color={theme?.primary} outlined style={{ marginBottom: 15 }} onClick={() => setStep(2)}>
                Tentar novamente
              </Button>
              <Button color={theme?.primary} onClick={handleUploadPhoto}>
                Continuar
              </Button>
            </PhotoPreviewArea>
          )}

          {/* CONCLUSION */}
          {step === 4 && !loading && (
            <CustomMessageArea>
              <Logo src={loadedLogo} />
              <h4>{inspection?.data?.conclusion_message}</h4>
            </CustomMessageArea>
          )}
        </>
      )}
    </Container>
  );
}

export default Home;
