import React from 'react';
import ReactLoading from 'react-loading';

import { Container, InctiveText, ActiveText } from './styles';

function Loading() {
  return (
    <Container>
      <InctiveText>configurando dispositivo</InctiveText>
      <InctiveText>carregando localização</InctiveText>
      <ActiveText>obtendo endereço IP...</ActiveText>
      <ReactLoading type="cylon" color="#E6E6E6" height={10} />
    </Container>
  );
}

export default Loading;
