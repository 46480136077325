import React from 'react';
import { StyledButton } from './styles';

function Button({ children, width, outlined, color, ...props }) {
  return (
    <StyledButton color={color} outlined={outlined} width={width} {...props}>
      {children}
    </StyledButton>
  );
}

export default Button;
