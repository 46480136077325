import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fafbfe;
`;

export const PhotoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  height: 50px;
  width: 200px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.05);
  font-size: 15px;
`;

export const Logo = styled.img`
  height: ${(props) => props.height || '30px'};
`;

export const CustomMessageArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;

  h3 {
    font-size: 26px;
    font-weight: normal;
    margin-top: 50px;
  }

  h4 {
    font-size: 19px;
    color: #6b6b6b;
    font-weight: 100;
    text-align: center;
    margin-bottom: 70px;
  }
`;

export const InstructionsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;

  h3 {
    font-size: 26px;
    font-weight: normal;
    margin-top: 70px;
  }

  h4 {
    font-size: 20px;
    color: #6b6b6b;
    font-weight: 100;
    text-align: center;
    margin-bottom: 70px;
  }
`;

export const PhotoRequestArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;

  h4 {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    color: #6b6b6b;
    text-align: center;
    font-weight: 100;
    margin-bottom: 30px;
  }
`;

export const ExampleImage = styled.div`
  margin-bottom: 30px;
  height: 280px;
  max-height: 280px;
  width: 280px;
  max-width: 280px;
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const PhotoPreviewArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;

  h4 {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    color: #6b6b6b;
    text-align: center;
    font-weight: 100;
    margin-bottom: 30px;
  }
`;

export const PreviewImage = styled.img`
  margin-bottom: 30px;
  height: 150px;
`;

export const ErrorArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;

  h3 {
    font-size: 26px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  h4 {
    font-size: 19px;
    color: #6b6b6b;
    font-weight: 100;
    text-align: center;
    margin-bottom: 70px;
  }

  small {
    position: absolute;
    bottom: 50px;
    font-size: 14px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const HelpArea = styled.div`
  display: flex;
  height: 90vh;
  min-height: 90vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 18px;
  }

  h2 {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    width: 90%;
    text-align: center;
  }

  a {
    font-size: 12px;
    margin-bottom: 30px;
    text-decoration: none;
  }

  li {
    margin-bottom: 10px;
    font-size: 13px;
  }
`;
