import React from 'react';
import Routes from './routes';
import { GlobalStyle } from 'styles/globalStyle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#a6a6a6' },
    },
  },
  [ptBR]
);

const App = () => (
  <div className="App">
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  </div>
);

export default App;
