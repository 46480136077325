import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props?.outlined ? 'transparent' : props.color || '#00E78E')};
  color: ${(props) => (props?.outlined ? props.color || '#00E78E' : '#fff')};
  border: ${(props) => (props?.outlined ? '1px solid' : 'none')};
  border-color: ${(props) => (props?.outlined ? props.color || '#00E78E' : 'transparent')};
  outline: none;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  font-size: 15px;
  border-radius: ${(props) => props.radius || '5px'};
  height: ${(props) => props.height || '45px'};
  width: ${(props) => props.width || '300px'};

  &&:hover {
    opacity: 0.8;
  }

  &&:disabled {
    cursor: default;
    opacity: 0.8;
  }
`;
