import { saveToS3 } from 'configs/aws';

export const saveFilesBucket = ({ Bucket, Key, Body, onEnd }) => {
  new Promise((resolve) => {
    saveToS3(Bucket, Key, Body, Body?.type)
      .then(() => {
        resolve({ file: Key });
      })
      .catch((err) => {
        console.log('error save to s3', err);
      });
  })
    .then((data) => {
      onEnd(null, data);
    })
    .catch((err) => onEnd(err, null));
};
