import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import Home from './screens/Home';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Cookies.get('authtoken') ? (
        <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact path="/:code" component={Home} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
