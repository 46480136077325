import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #f5f9fd;
  box-sizing: border-box;
  padding: 80px 40px;
`;

export const InctiveText = styled.h2`
  color: rgba(0, 0, 0, 0.2);
  font-size: 20px;
  margin: 0px;
  margin-bottom: 15px;
  transition: all ease-in-out 0.3s;
`;

export const ActiveText = styled.h2`
  font-size: 22px;
  transition: all ease-in-out 0.3s;
`;
